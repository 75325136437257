/* header */
header.uat {
  background-color: #e30133;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

header {
  color: #fff;
  padding-top: 10px;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
.nav>li>a,
.navbar,
header,
footer,
.default-font {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 36px;
}

.nostyle {
  text-decoration: none !important;
}

.title a.nostyle:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #000;
}

a:hover,
a:active {
  outline: none;
}

/* navbar */
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  font-weight: 300;
}

nav {
  background: #666;
  color: #fff;
  border-radius: 0;
  margin-bottom: 20px;
}

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: #fff;
}

/* @media (min-width: 992px)
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
} */
.navbar-nav li>a:hover,
.navbar-nav .nav>li>a:focus {
  color: #fff;
  background-color: #cb1443;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: #fff;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.navbar a:hover {
  background-color: #cb1443;
  color: #fff;
}

.navbar-nav .nav-link {
  color: #fff;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-link:focus,
.nav-link:hover {
  color: #0a58ca;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

/* Footer */
/* footer {
  bottom: 0;
  background: #e30133;
  color: #fff;
  border-top: 5px solid #cb1443;
  width: 100%;
  white-space: nowrap;
  height: 80px;
  display: block;
  margin-top: 30px;
} */

a:hover {
  color: #000;
}

a:hover,
a:active {
  outline: none;
}

/* Policies */
.hidden {
  display: none;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.formhead {
  font-size: 2.2em;
  color: #4e3782;
  text-shadow: 2px 2px 1px #ccc;
  margin: 20px 0px 40px 0px;
  clear: both;
  padding: 5px 0px;
  border-bottom: 2px solid #4e378294;
  text-align: center;
  font-weight: bold;
}

label {
  margin-bottom: 5px !important;
  font-size: 15px;
}

.userinformationctrl {
  border-radius: 10px;
}

#userinfo-title {
  font-size: 1.2em;
  margin: -20px -20px 20px -20px;
  padding: 8px 20px;
  background-color: #00747b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
}

.userinfo-bodytext {
  margin: 5px 0px;
  font-size: 0.9em;
}

.whitepanel {
  background-color: #fff;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
}

.userinfo-section {
  background-color: #fff;
  padding: 10px;
  /* border: 1px solid #ccc; */
}

.userinfo-section .btn {
  font-family: Roboto Slab !important;
  /*box-shadow: 0px 0px 5px #D00;*/
}

.userinfo-sectionheader {
  font-size: 1em;
  font-weight: 400;
  color: #fff;
  background-color: #00747b;
  /*border-top-left-radius: 10px;*/
  /*border-top-right-radius: 10px;*/
  padding: 5px 5px 5px 10px;
  min-height: 20px;
}

.userinfo-section .form-group label,
.inputaddress label {
  font-size: 90%;
}

.userinfo-section .form-control {
  padding: 2px 12px;
  font-size: 90%;
  height: 30px;
}

#inputaddressMT .input-group-addon {
  font-size: 85%;
  min-width: 150px;
  text-align: right;
  background-color: #aaa;
  color: #fff;
}

#inputaddress .form-control {
  text-transform: capitalize;
}

#inputaddress .col-sm-12 {
  margin-bottom: 5px;
}

.row {
  margin-bottom: 0px;
}

.form-group {
  margin-bottom: 25px !important;
}

.form-control {
  box-sizing: border-box !important;
}

.form-control:not(.multivalidate) {
  width: 100% !important;
}

.form-group:not(.btn) label {
  /* font-family: Roboto Slab; */
  font-weight: 800;
}

/* #ContentPlaceHolder1_pnlForm p,
label {
  font-family: Roboto Slab;
} */

.weblogo {
  padding: 10px 0px !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.invalid {
  font-size: 0.9em !important;
}

.multivalidate {
  color: #f5f5f5 !important;
  background-color: #f5f5f5 !important;
  user-select: none;
}

[data-toggle="buttons"].btn-group .btn-primary {
  border-width: 1px !important;
  border-radius: 0px !important;
}

.btn,
.button,
input[type="submit"] {
  border-width: 1px !important;
  border-radius: 0px !important;
}

#location-widget {
  margin: 3px;
  padding: 0px;
  height: 600px;
  box-shadow: 0px 0px 10px #aaa !important;
}

#mapbannerHighways {
  margin-top: 0px !important;
  padding: 10px 12px;
  border-top-width: 0px;
  box-shadow: 0px 0px 10px #aaa;
  background-color: rgb(221, 221, 221);
}

.formwrapper {
  width: 100%;
  /*padding-left: 10px;*/
  /*padding-right: 10px;*/
}

.formwrapper textarea {
  min-height: 130px !important;
}

.formwrapper .weblogo {
  padding: 5px;
  background: #fff;
}

.formwrapper .formcontent h4,
.formwrapper .whitepanel h4 {
  font-size: 1.2em !important;
}

.formwrapper .formfooter {
  padding-top: 10px;
  text-align: center;
}

.formfooter hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.formfooter input {
  margin: 0px 5px;
}

.userinformation h4 {
  font-size: 1.2em !important;
  text-align: center;
}

.userinformation {
  padding: 20px;
  background-color: #ffffe0;
  /*background-color: #FFF;*/
  margin-bottom: 40px;
  /*font-family: Roboto Slab;*/
  -ms-user-select: none;
  margin-top: 20px;
  box-shadow: 0px 0px 6px #d00;
  margin: 3px 3px 50px 3px;
}

.userinfo-link {
  font-weight: 400 !important;
  text-decoration: none !important;
  color: blue !important;
}

.userinformation a:link,
.userinformation a:visited,
.formfooter a:link,
.formfooter a:visited {
  color: #fff;
}

.userinformation .title {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.userinformation .contact {
  margin-top: 10px;
}

#llpgwidget div {
  padding-top: 5px;
  padding-bottom: 5px;
}

#llpgeventwidget div {
  padding-top: 5px;
  padding-bottom: 5px;
}

.invalid {
  font-size: 1em;
  font-weight: bold;
  color: red;
  margin-top: 5px;
  padding-left: 10px;
}

#panel {
  margin-bottom: 5px;
}

fieldset {
  padding: 0 !important;
  margin: 0 !important;
}

/* Radio buttons */
/* Applying a 2px border means the lins in the middle are 4px wide. Instead, use CSS selectors to increse left and right sides of first and last items */
.btn-group[data-toggle="buttons"] .btn-primary {
  background-color: #ffffff;
  border-color: #82b400;
  border-width: 2px 1px 2px 1px;
  font-weight: normal;
  color: #000000;
}

.btn-group .btn {
  font-family: inherit !important;
}

.btn-group .btn:hover {
  background-color: #eee;
}

.btn-group[data-toggle="buttons"] .btn-primary:first-child {
  border-left-width: 2px;
}

.btn-group[data-toggle="buttons"] .btn-primary:last-child {
  border-right-width: 2px;
}

.btn-group .btn-primary.active {
  color: #fff;
  background-color: #82b400;
}

/* Fix overlay problem with bootstrap buttons and jquery ui datepicker */
#ui-datepicker-div {
  z-index: 999 !important;
}

.ui-datepicker {
  font-family: Roboto Slab !important;
}

.files div {
  padding: 5px;
  float: left;
}

.alert-danger p {
  font-weight: bold;
  text-align: center;
  font-size: 1em !important;
}

.addressarea {
  padding: 5px !important;
  background-color: #ccc;
  color: #ffffff;
  text-align: center;
  margin-top: 5px;
}

.geodetails {
  font-weight: 400;
  color: #444;
  font-size: small;
}

#locationdetails {
  font-size: 0.7em;
  text-align: right;
  margin-top: 3px;
  display: none;
}

.gmnoprint img {
  max-width: none;
}

#geoaddress {
  margin-bottom: 0px !important;
}

#ContentPlaceHolder1_divWrapper form li {
  margin-bottom: 0px !important;
}

.userinfo {
  font-size: 0.9em;
  font-family: "Roboto Slab";
}

.user-title,
.user-firstName,
.user-lastName {
  font-size: 1.1em;
  font-weight: 600;
}

#etlink {
  text-decoration: none;
  opacity: 0.8;
}

#etlink:hover {
  opacity: 1;
}

.btn {
  padding: 5px 15px !important;
  min-width: 70px;
}

#popupwhatarethesedetails {
  background-color: #d0d0d0;
  color: #666;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: 0.8em;
}

#popupwhatarethesedetails:hover {
  text-decoration: none;
}

select {
  padding: 4px 4px 4px 12px !important;
}

#searching,
#searchingEvent {
  color: #aaa;
  margin-left: 15px !important;
}

#devEnvBanner {
  font-family: Roboto Slab;
  background-color: #cb1443;
  font-size: 1.1em;
  font-weight: 200;
  color: #fff;
  padding: 15px;
  border: 1px #aaa solid;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin: 50px 15px 15px 15px;
  box-shadow: 0px 0px 20px #000;
}

#devEnvBanner a {
  font-family: Roboto Slab;
  color: #fff !important;
  font-weight: 800;
}

#devEnvBanner i {
  float: left;
  margin-right: 20px;
}

.formhead,
.userinformation,
.formfooter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.contentsblur {
  opacity: 0.5;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  pointer-events: none;
}

#ContentPlaceHolder1_lblTitle {
  margin-left: 5px;
  text-transform: capitalize;
}

table th {
  padding: 16px 20px !important;
  text-align: center;
}

/* table tbody tr:hover {
  background-color: #d8d3e37a;
} */
table tr {
  border-bottom: 1px solid #d8d3e3 !important;
}

table td {
  border: 0px solid #fff !important;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  padding: 16px 20px !important;
  text-align: center;
  vertical-align: middle;
}

.formcontent {
  border: 0px solid #ddd;
}

.col-sm-12 {
  margin-bottom: 2px;
}

#successMessage {
  cursor: default;
  -ms-user-select: none;
  user-select: none;
}

#successMessage li {
  overflow: visible !important;
  margin-bottom: 0px !important;
}

.weblogo {
  background-color: whitesmoke !important;
}

#wrapper {
  background-color: whitesmoke !important;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
}

.weblogo p {
  font-family: Roboto Slab;
  font-weight: 800;
}

.btn-group .btn {
  font-family: inherit;
}

#contentDoc {
  min-height: 800px;
}

#files img {
  height: 90px;
  width: 90px;
  border-top: 15px solid #fff;
  border-bottom: 15px solid #fff;
  box-shadow: 0px 0px 5px #777;
  margin-right: 10px;
}

.btn-rnd {
  border-radius: 10px !important;
}

#Appointment_Date {
  overflow-x: scroll;
}

#Appointment_Date_Label {
  color: #fff;
  padding-right: 0px;
  text-align: right;
  margin-top: 5px;
}

#Appointment_Searching {
  margin: 0px 0px 20px 0px;
}

#Appointment_Date_Search {
  font-weight: 400;
}

/* HIDE GOOGLE TRANSLATE ELEMENT TEMPORARILY */
.google_translate_element {
  display: none !important;
}

#appointments--iconbody {
  position: relative;
}

#appointments--body {
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
  font-family: Roboto Slab;
  box-shadow: inset 0px 0px 8px 0px #ababab;
  -webkit-box-shadow: inset 0px 0px 8px 0px #ababab;
  -moz-box-shadow: inset 0px 0px 8px 0px #ababab;
  -o-box-shadow: inset 0px 0px 8px 0px #ababab;
}

#Appointments_Container button {
  margin: 5px !important;
}

.AppointmentBtn {
  padding: 10px;
  font-size: 0.9em;
  font-family: "Roboto Slab";
  text-align: right;
  width: 175px;
  min-width: 175px;
  max-width: 175px;
  border: 1px outset #aaa !important;
}

#appointments--icon {
  color: #444;
}

@media (min-width: 768px) {
  .topspacing {
    display: none;
  }
}

#Appointment_SearchBar {
  width: 100%;
  line-height: 25px;
  font-family: Roboto Slab;
  font-size: 0.9em;
  font-weight: 200 !important;
}

.datepicker-days td:not(.disabled) {
  font-weight: 600;
}

.Appointment__Initial--Text {
  margin: 50px 0px;
  color: #aaa;
  font-size: 1.7em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

#Appointment__Banner {
  margin-left: 0px !important;
}

#appointment-widget {
  /*margin: 10px;*/
}

#Appointment_Date>.ui-widget {
  border: 0px !important;
}

#Appointment_Title {
  font-size: 1.8em;
  color: #888;
  font-family: Roboto Slab;
  font-weight: 800;
}

#Appointment_Title_Icon {
  color: #888;
  margin-right: 15px;
  font-size: 2.5em;
}

#Appointment_Duration {
  color: #bbb;
  font-family: Roboto Slab;
  font-weight: 800;
  text-align: right;
}

#Appointment_Duration i {
  margin-right: 5px;
}

@media only screen and (max-width: 1024px) {
  #Appointment_Container {
    overflow-x: scroll;
  }
}

#Appointment_Container {
  margin-bottom: 0px !important;
}

.ui-datepicker-calendar {
  font-size: 1em !important;
}

.ui-datepicker table td a {
  font-size: 1em !important;
}

.ui-datepicker-current-day a {
  font-weight: 600 !important;
}

.userinformation__button {
  max-width: 225px !important;
}

#email_address_spinner {
  top: 18px !important;
  color: #888;
  display: none;
}

#MT_address_spinner {
  top: 18px !important;
  color: #888;
  display: none;
}

#MyTelfordRegisteredBanner,
#MyTelfordPossiblyRegisteredBanner,
#EverydayTelfordRegisteredBanner,
#TLCRegisteredBanner {
  display: none;
}

.swal2-cancel {
  margin-left: 10px !important;
}

.swal2-container {
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.formfooter .alert {
  margin-bottom: 0px !important;
  font-family: Segoe UI Light !important;
}

.formfooter .alert hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

#privacynotice__title {
  font-size: 1.2em !important;
  font-weight: 600 !important;
}

#privacynotice__icon {
  color: rgb(85, 85, 85);
  margin-right: 5px;
}

#privacynotice__title {
  text-align: center !important;
}

#privacynotice__text {
  text-align: left !important;
  font: 14px/1.58em "Helvetica", Arial, sans-serif !important;
}

#privacynotice__text strong {
  color: #333 !important;
}

#privacynotice__text a {
  color: blue !important;
}

#privacynotice__text a:visited {
  color: darkblue !important;
}

.ui-datepicker-month,
.ui-datepicker-year {
  color: black !important;
}

#map__legend {
  width: 100%;
  background-color: #ddd;
  padding: 5px;
  font-size: 0.8em !important;
  border: 2px solid #ccc;
  margin-top: 5px;
}

.map__legend_item {
  padding: 5px 10px;
}

.map__legend_item_img {
  margin-right: 5px;
}

.link {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  overflow: visible;
  padding-left: 5px;
}

/* Notifications */
.notification {
  visibility: visible;
  opacity: 1;
  transform: translateX(-410px);

  border: 1px solid rgba(100, 100, 100, 0.4);
  width: 430px;
  font-size: 12px;
  box-shadow: 0 3px 8px rgb(0 0 0 / 25%);
  position: absolute;
  z-index: 1;
  background-color: white;

  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.header-title {
  color: #1d2129;
  font-weight: bold;
}

.header-option:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header-option {
  margin: 0 5px;
  color: #365899;
}

.items {
  height: 400px;
  overflow-y: auto;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fieldValidation {
  /* color: red; */
}

.shadowBox {
  box-shadow: 0px 0px 10px #ccc;
}

.error {
  color: #dc3545;
  font-size: 1rem;
  margin: 0 0 0 5px;
  display: block;
}

.center {
  margin: auto;
  align-content: center;
  display: flex;
  text-align: center;
  justify-content: center;
  height: 67vh;
}