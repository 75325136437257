body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}

.navbar-toggler:hover {
  text-decoration: none !important;
  background-color: unset !important;
  border: unset !important;
}

.navbar-nav .dropdown-menu {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  width: 200px;
}

.navbar-toggler {
  box-shadow: unset !important;
}

nav.navbar {
  background-color: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-header {
  background-color: #4e3782;
  padding: 20px;
  border-bottom: 16px solid #005cb9;
}

.logo img {
  width: 170px;
}

.logo-2 {
  width: 320px !important;
  margin: 0 0 0 30px;
}

.nav-box {
  background: unset;
  margin: unset !important;
}

.nav-box ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-box ul li {
  cursor: pointer;
  margin: 0 7px;
}

.nav-box ul li:after {
  border-bottom: 2px solid;
  display: none;
}

.nav-box ul li::after {
  content: "";
  border-bottom: 2px solid;
}

.nav-box ul li:after {
  content: "";
}

.nav-box ul li {
  position: relative;
  font-size: 19px;
  font-weight: bold;
  border-radius: 6px;
  margin: 0 20px;
}

.nav-box ul li::before {
  content: "";
  position: absolute;
  border-bottom: 2px solid;
  width: 0;
  bottom: 0;
  transition: 0.7s cubic-bezier(0.76, 0, 0.24, 1);
}

.nav-box ul li:hover::before {
  width: 100%;
}

.signup-sec {
  background-color: #4e3782;
  color: #fff;
  padding: 40px 0;
  /* margin: 12px 0 0 0; */
}

.signup-sec h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.signup-sec p {
  margin: 0;
  font-size: 18px;
}

.signup-btn {
  padding: 20px 55px !important;
  background-color: #b8d433 !important;
  font-size: 22px;
  font-weight: bold;
  border: unset;
  border-radius: 6px !important;
  color: #fff;
}

.footer-li ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.footer-li ul li {
  list-style-type: none;
  margin: 0 30px;
  font-size: 20px;
  position: relative;
}

.footer-li ul li a {
  text-decoration: none;
  color: #fff;
}

.footer-li ul li::before {
  content: "";
  position: absolute;
  border-bottom: 2px solid;
  width: 0;
  bottom: 0;
  transition: 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.footer-li ul li:hover::before {
  width: 100%;
}

footer {
  background-color: #4e3782;
  margin: 20px 0 0 0;
  color: #fff;
  padding: 37px 0 !important;
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0; */
}

footer p {
  font-size: 19px;
  margin: 0;
}

.apply-transport-btn {
  background-color: #00767b !important;
  border: none !important;
  padding: 10px 15px !important;
  border-radius: 6px !important;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
}

.apply-transport-btn:hover {
  color: #fff !important;
  transform: scale(1.1);
}

.alert-info {
  color: #4e3782 !important;
  background-color: #4e378238 !important;
}

.table {
  background-color: #fff;
  box-shadow: 0 0 3px 3px #33333326;
  border-radius: 6px;
  padding: 30px 20px;
  margin-top: 27px !important;
}

.card-header {
  background-color: #4e3782 !important;
  color: #fff !important;
  padding: 13px 15px !important;
}

.card-header h5 {
  margin: 0 !important;
}

.card-body h5 {
  padding: 14px 0 14px 0;
}

/* .margin-top-0 h5 {
  margin-top: 0px !important;
} */
.card-body strong {
  margin: 6px 0;
  display: block;
  font-weight: 500;
}

.form-check-input:checked {
  background-color: #4e3782 !important;
  border-color: #4e3782 !important;
}

.card-body p {
  font-weight: 600;
}

.form-check-input {
  margin: 7px 8px 7px 0px !important;
}

.card {
  box-shadow: 0 0 14px 2px #33333329 !important;
  border: unset !important;
  margin: 30px 0 !important;
}

.form-control:not(.multivalidate) {
  width: 100% !important;
  box-shadow: unset !important;
}

.form-control:focus {
  border-color: #ced4da !important;
}

.nav-dropdown.dropdown button {
  background-color: unset;
  border: unset;
  border-top-width: unset;
  border-right-width: unset;
  border-bottom-width: unset;
  border-left-width: unset;
  font-size: 19px;
  font-weight: bold;
  padding: 0 10px !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: unset !important;
  border-color: unset !important;
}

.card.list-card {
  padding: 30px 30px;
}

.tr-table-btn button {
  padding: 10px 15px;
  border: unset;
  background-color: #005cb9;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
}

.table-approve button {
  background-color: #005cb9 !important;
}

thead {
  background-color: #4e3782;
  color: #fff;
}

.list-table h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 25px 0 0 0 !important;
  color: #4e3782;
}

.policies-box h3,
.policies-box h4 {
  font-size: 20px;
  color: #4e3782;
  font-weight: bold;
  margin: 0 0 20px 0;
  padding: 0 40px 0 0;
  line-height: 31px;
}

.index-form-box select {
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  height: unset !important;
}

.index-form-box input {
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  height: unset !important;
}

.index-form-box .card {
  border: unset !important;
  padding: 30px 30px;
}

.step2-box h2 {
  font-size: 25px;
  color: #4e3782;
  font-weight: bold;
}

.policies-box a:hover {
  color: #f08e00;
}

.policies-box a {
  color: #4e3782;
}

.step2-box label {
  font-size: 15px;
  margin: 0 0 6px 0 !important;
  display: block;
  color: #4e3782;
}

.alert-warning {
  background-color: #f18f002b !important;
  border-color: #f18f002b !important;
}

.setting-box {
  padding: 20px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 3px #cccccc69;
  border-radius: 7px;
}

.setting-box input {
  margin: 0 0 20px 0;
}

.setting-box label {
  font-size: 16px;
  margin: 0 0 5px 0 !important;
  display: block;
}

.settings-main h2 {
  font-size: 30px;
  color: #4e3782;
  text-shadow: 2px 2px 1px #ccc;
  font-weight: m;
  margin: 30px 0 14px 0 !important;
}

.settings-main button {
  border: none;
  padding: 10px 30px;
  background-color: #f08f00;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin: 50px 0 20px 0;
  border-radius: 7px;
}

.index-form-btn {
  border: none;
  padding: 10px 30px;
  background-color: #f08f00;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin: 50px 0 20px 0;
  border-radius: 7px;
}

.navbar-nav li > a:hover,
.navbar-nav .nav > li > a:focus {
  background-color: unset !important;
}

.confirmation-form h2 {
  font-size: 25px;
  color: #4e3782;
  font-weight: bold;
}

.confirmation-form h3 {
  font-size: 25px;
  color: #4e3782;
  font-weight: bold;
}

.confirmation-form .form-check {
  text-align: left;
  margin-left: unset !important;
  padding-left: unset !important;
  display: flex;
  /* display: -webkit-box; */
}

.nav-dropdown button {
  font-size: 16px;
  font-weight: initial;
}

.dropdown-menu li {
  font-size: 17px !important;
  font-weight: normal !important;
  padding: 5px 9px;
  margin: 0 !important;
}

.dropdown-menu li:hover::before {
  width: 80% !important;
}

.dropdown-menu li a {
  text-decoration: unset;
  color: unset;
}

.nav-box li a {
  text-decoration: unset;
  color: unset;
}

.tr-table-btn button a {
  text-decoration: unset;

  color: #fff;
}

.tr-table-btn {
  width: 248px;
  text-align: center;
}

/* .table > thead:hover {
  background-color: #0069ae !important;
} */
button:hover {
  transform: scale(1.06);
  transition: 0.8s all;
}

button {
  transition: 0.8s all;
}

.setting-box .form-select {
  padding: 0.375rem 0.75rem !important;
  color: #747c84;
  box-shadow: unset !important;
}

.form-select:focus {
  border-color: unset !important;
}

.section-three {
  padding: 30px 0;
  /* margin: 20px 0; */
  color: #4e3782;
  border-radius: 10px;
}

.section-three h2 {
  font-size: 32px;
  font-weight: bold;
}

.section-three p {
  font-size: 18px;
  /* width: 80%; */
}

.section-three a {
  color: #f08f00;
}

.banner-sec img {
  height: 590px;
  object-fit: fill;
}

.carousel-control-next,
.carousel-control-prev {
  top: 50% !important;

  width: 50px !important;
  opacity: 1 !important;
  height: 50px !important;
  background-color: #ccccccd9 !important;
  transform: translate(0, -50px) !important;
  border-radius: 100% !important;
}

.carousel-control-next {
  right: 30px !important;
}

.carousel-control-prev {
  left: 30px !important;
}

.carousel-indicators [data-bs-target] {
  height: 5px !important;

  background-color: #ccccccd9 !important;
}

@media screen and (max-width: 1200px) {
  .logo img {
    width: 150px !important;
  }

  .logo .logo-2 {
    width: 275px !important;
  }

  .nav-box ul li {
    font-size: 16px !important;

    margin: 0 11px !important;
  }

  .nav-dropdown.dropdown button {
    font-size: 16px !important;
  }

  .signup-sec h2 {
    font-size: 25px;
  }

  .signup-btn {
    padding: 13px 35px !important;
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  .table-width-box {
    width: 100%;
    overflow-x: scroll;
    padding: 0 0 0 4px;
  }

  .navbar-nav .dropdown-menu {
    background-color: unset !important;
    border: unset !important;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    background-color: unset;
    border: unset;
    margin: 0;
    padding: 0;
  }

  .list-table::-webkit-scrollbar {
    display: block !important;
  }

  nav.navbar.navbar-expand-lg.navbar-light {
    background-color: unset;
  }

  button.navbar-toggler.collapsed {
    background-color: unset;
    box-shadow: unset;
    border: unset !important;
  }

  .navbar-collapse.collapse {
    position: absolute;
  }

  .navbar-collapse.collapse,
  .navbar-collapse.collapsing,
  .navbar-collapse.collapse.show {
    position: absolute;
    right: 0;
    background-color: #4e3782;
    color: #fff !important;
    width: 100%;
    padding: 20px 10px;
    height: unset !important;
    border-radius: 10px;
    border: 1px solid #fff;
    top: 55px;
    z-index: 9;
  }

  .navbar-nav-scroll {
    max-height: unset !important;
  }

  .nav-box ul li {
    margin: 13px 11px !important;
    color: #fff8f8;
  }

  .nav-dropdown.dropdown button {
    color: #fff;
    margin: 10px 0;
  }

  .nav-box ul {
    display: block;
  }

  .dropdown-menu {
    background-color: unset;
    border: unset;
  }

  .dropdown-menu li {
    margin: 0 !important;
  }

  .navbar-toggler:hover {
    background-color: #fff;
  }

  .signup-sec h2 {
    font-size: 23px;
  }

  .signup-sec p {
    font-size: 16px;
  }

  .signup-btn {
    width: 160px;
    margin: 0 0 0 50px;
  }

  .section-three p {
    width: 100%;
  }

  .footer-li ul li {
    margin: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .col-md-3.fw-bold.d-flex.justify-content-end {
    justify-content: start !important;
  }

  header.main-header .container {
    max-width: 100%;
  }

  .banner-sec img {
    height: unset;
  }

  .signup-sec .d-flex {
    display: block !important;
  }

  .signup-btn {
    width: 160px;
    margin: 30px 0 0 0;
  }

  .signup-sec p {
    margin: 17px 0 0 0;
  }

  footer p {
    font-size: 14px;
  }

  .footer-li ul li {
    margin: 0 8px;
    font-size: 15px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 30px !important;
    height: 30px !important;
  }

  .formhead {
    font-size: 1.8em !important;
  }
}

@media screen and (max-width: 639px) {
  header.main-header .container {
    padding: 0;
  }

  .logo img {
    width: 120px !important;
  }

  .logo .logo-2 {
    width: 175px !important;
  }

  .navbar-toggler-icon {
    width: 1.2em;
    height: 1.2em;
  }

  .section-three p {
    font-size: 16px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    transform: translate(0, -20px) !important;
  }

  .banner-sec img {
    height: 300px;
  }
}

@media screen and (max-width: 479px) {
  .settings-main h2 {
    font-size: 24px;
  }

  .logo .logo-2 {
    display: none;
  }

  .signup-sec h2 {
    font-size: 20px;
    line-height: 31px;
  }

  .signup-sec {
    padding: 25px 0;
  }

  .signup-btn {
    width: 137px;
    font-size: 17px;
  }

  .section-three h2 {
    font-size: 28px;
  }

  footer .d-flex {
    display: block !important;
  }

  footer p {
    text-align: center;
  }

  .footer-li ul {
    justify-content: center;
    margin: 15px 0 0 0;
  }
}

nav.navbar {
  margin-bottom: 0px;
}

.navbar-nav li > a:hover,
.navbar-nav .nav > li > a:focus {
  color: unset !important;
}

table.table {
  min-width: 992px;
}

.list-table {
  overflow-x: scroll;
  padding: 0 10px;
}

.list-table::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  display: none;
}

/* Track */
.list-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.list-table::-webkit-scrollbar-thumb {
  background: #4e3782;
}

/* Handle on hover */
.list-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.navbar-toggler-icon {
  background-image: url(../src/images/menu.png) !important;
}

.riskassessment-footer {
  min-height: calc(100vh - 238px);
}

.col-md-3.fw-bold {
  font-weight: 600 !important;
  font-size: 14px;
}

.col-md-5.fw-bold {
  font-weight: 600 !important;
}

.col-md-4 .fw-bold {
  font-weight: 600 !important;
}

.policies-box {
  height: 500px;
  overflow: scroll;
  padding: 20px 10px;
  border: 1px solid #ccc;
}

.policies-box-heading h3 {
  font-size: 25px;
  color: #4e3782;
  font-weight: bold;
}

.modal-body {
  font-family: initial;
  margin: 0;
  padding: 0 20px !important;
}

.modal-title.h4 {
  font-weight: 600;
  font-family: initial;
  font-size: 24px;
}

.modal-header {
  border-bottom: unset !important;
}

.modal-footer {
  border: unset !important;
}

.modal-btn2 {
  background-color: #4e3782 !important;
  border: unset !important;
  padding: 8px 22px !important;
  border-radius: 4px !important;
}
.modal-btn2 a {
  color: #fff;
  text-decoration: unset !important;
}
.modal-btn2 a:hover {
  color: #fff !important;
}
.modal-box {
  height: unset !important;
  overflow: auto !important;
  border-radius: 6px;
}
.modal-box p {
  font-size: 16px;
  font-family: roboto, sans-serif;
  margin: 0;
}

.modal-btn.btn.btn-primary {
  background-color: #4e3782 !important;
  border: unset !important;
  padding: 12px 25px !important;
  border-radius: 8px !important;
}

.modal-btn.btn.btn-primary:hover {
  background-color: #4e3782 !important;
}

.policies-box::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  /* display: none; */
}

/* Track */
.policies-box::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 10px;
}

/* Handle */
.policies-box::-webkit-scrollbar-thumb {
  background: #4e3782;
  width: 10px;
}

/* Handle on hover */
.policies-box::-webkit-scrollbar-thumb {
  background: #4e3782;
  width: 10px;
  border-radius: 10px;
}

.form-check-input[type="checkbox"] {
  min-width: 16px !important;
}
.disabled-btn:disabled {
  background-color: gray !important;
  color: #fff !important;
}
.transport-agreement p {
  font-size: 18px;
}

.agreement-list-box span {
  font-size: 20px;
  margin: 0 0 10px 0;
  display: block;
}
.agreement-list-box li {
  font-size: 19px;
  margin: 0 0 10px 0;
}

.agree-box {
  border: 1px solid #ccc;
  padding: 10px 25px;
}
.transport-agreement h5 {
  font-weight: bold;
  padding: 30px 0 14px 0;
}
.transport-letterbox input {
  margin-bottom: 0px !important;
}
